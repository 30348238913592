import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useDispatch } from "react-redux";
import { IFrameType } from "store/VideoSession/types";
import { useGatedContent } from "hooks/useGatedContent";
import { setActiveModal } from "store/Modals/slice";
import { GenericModal } from "../GenericModal";
import { TextInput } from "components/inputs/text-input/TextInput";
import { client } from "api/playerClient";
import {
    EntitlementType,
    PasswordValidationEntityRequest,
    PasswordValidationRequest,
    PasswordValidationResponse
} from "@switcherstudio/player-api-client";
import { Modals } from "store/Modals/types";
import { PasswordClaim } from "store/CreatorCustomers/types";
import { trackSegmentEvent } from "helpers/analyticsHelpers";
import useCollectionWithVideos from "hooks/useCollectionWithVideos";

export const PasswordAccessModal: React.FC = () => {
    const { t } = useTranslation();

    const [error, setError] = useState<string | undefined>();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((s: RootState) => s.loading);
    const { iframeType, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );
    const creatorProjectId = useSelector(
        (s: RootState) => s.playerCreator?.details?.projectId
    );

    const [password, setPassword] = useState<string | null>(null);

    const isValidPassword = (password: string | null) => {
        return password !== null && password.length > 3;
    };

    const { catalog } = useSelector((s: RootState) => s.catalogState);
    const { collection } = useCollectionWithVideos();
    const { currentCollectionVideo } = useSelector(
        (s: RootState) => s.videoSession
    );

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const {
        catalogPasswordEntitlements,
        playerPasswordEntitlements,
        playlistBroadcastPasswordEntitlements,
        availablePasswordEntitlements,
        availableEmailEntitlements
    } = useGatedContent();

    const entityName = useCallback(
        (entityType: EntitlementType | undefined) => {
            switch (entityType) {
                case "Catalog":
                    return catalog?.details?.title;

                case "Collection":
                    return collection?.details?.name;

                case "Video":
                    return currentCollectionVideo?.broadcast?.details?.title;
            }
        },
        [catalog, collection, currentCollectionVideo]
    );

    const entities = useMemo(() => {
        return availablePasswordEntitlements.map(
            (entitlement) =>
                new PasswordValidationEntityRequest({
                    entityType: entitlement.details.type,
                    entityId: entitlement.details.entitlementId,
                    entityName: entityName(entitlement.details.type),
                    projectId: creatorProjectId
                })
        );
    }, [availablePasswordEntitlements, creatorProjectId, entityName]);

    const submitPassword = useCallback(
        async (password: string) => {
            try {
                setIsProcessing(true);
                const request = new PasswordValidationRequest({
                    password,
                    entities
                });
                const response: PasswordValidationResponse =
                    await client.validate(request);
                if (response.isValid) {
                    setError(undefined);

                    const passwordClaimsToStore =
                        response.validEntities?.map((entity) => {
                            const passwordClaim = {
                                Password: password,
                                EntityId: entity?.details?.entityId,
                                EntityType: entity?.details?.entityType,
                                DateRedeemed: new Date()
                            } as PasswordClaim;

                            trackSegmentEvent("Password Redemption", {
                                Password: passwordClaim.Password,
                                EntityType: passwordClaim.EntityType,
                                EntityId: passwordClaim.EntityId,
                                DateRedeemed: passwordClaim.DateRedeemed
                            });

                            return passwordClaim;
                        }) || [];
                    // Allows the parent frame to refresh the player video session after password is entered.
                    parentFrame?.distributePasswordClaims(
                        creatorProjectId ?? "",
                        passwordClaimsToStore
                    );

                    setIsProcessing(false);

                    dispatch(setActiveModal(Modals.None));
                } else {
                    setError(t("errors:invalid-password"));
                    setIsProcessing(false);
                }
            } catch (e) {
                setError(t("errors:validation-failed"));
                setIsProcessing(false);
            }
        },
        [entities, parentFrame, creatorProjectId, dispatch, setError, t]
    );

    const description = useMemo(() => {
        if (catalogPasswordEntitlements.length > 0) {
            return t("purchase-options:password-cta-catalog");
        }
        if (playerPasswordEntitlements.length > 0) {
            return t("purchase-options:password-cta-collection");
        }
        if (playlistBroadcastPasswordEntitlements.length > 0) {
            return t("purchase-options:password-cta-video");
        }
        return "";
    }, [
        catalogPasswordEntitlements,
        playerPasswordEntitlements,
        playlistBroadcastPasswordEntitlements,
        t
    ]);

    const footerLink = useMemo(() => {
        if (availableEmailEntitlements.length > 0) {
            return t("purchase-options:email-unlock");
        } else {
            return "";
        }
    }, [availableEmailEntitlements, t]);

    const openPasswordAccessModal = useCallback(() => {
        dispatch(setActiveModal(Modals.PasswordAccess));
    }, [dispatch]);

    return (
        <GenericModal
            title={t("modals:enter-password")}
            successButton={t("buttons:submit")}
            onSuccess={() => submitPassword(password ?? "")}
            onClose={() => {
                iframeType === IFrameType.AuxModal &&
                    parentFrame?.closePurchaseModal();
            }}
            successButtonDisabled={
                !isValidPassword(password) || isLoading || isProcessing
            }
            preventCloseOnSuccess={true}
            preventDismiss={isLoading}
            footerLinkText={footerLink}
            footerLinkAction={openPasswordAccessModal}
            isProcessing={isProcessing}
            processingHeader={t("modals:processing")}
            processingDescriptionLines={[t("modals:processing-description")]}
        >
            <p>{description}</p>
            <TextInput
                id="password"
                type="text"
                value={password ?? ""}
                error={error}
                onChange={(e) => setPassword(e.target.value)}
                label={t("inputs:password")}
                disabled={isLoading}
            />
        </GenericModal>
    );
};
