import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";
import {
    CreatorCustomersState,
    ICreatorCustomerTicket,
    PasswordClaim,
    TokenPayload
} from "./types";
import { exchangeCode, refreshToken } from "./thunks";

const initialState = {} as CreatorCustomersState;

export const creatorCustomersSlice = createSlice({
    name: "creatorCustomers",
    initialState: initialState,
    reducers: {
        setCreatorCustomerTicket: (
            state,
            action: PayloadAction<ICreatorCustomerTicket>
        ) => {
            const creatorProjectId = action.payload.projectId;
            if (creatorProjectId) {
                state[creatorProjectId] = {
                    ...state[creatorProjectId],
                    ticket: action.payload
                };
            }
        },
        setPasswordClaims: (
            state,
            action: PayloadAction<{
                projectId: string;
                passwordClaims: PasswordClaim[];
            }>
        ) => {
            const creatorProjectId = action.payload.projectId;
            if (creatorProjectId) {
                state[creatorProjectId] = {
                    ...state[creatorProjectId],
                    passwordClaims: action.payload.passwordClaims
                };
            }
        }
    },
    extraReducers(builder) {
        builder.addCase(exchangeCode.fulfilled, (state, action) => {
            const creatorProjectId = action.payload.projectId;
            if (creatorProjectId) {
                state[creatorProjectId] = {
                    ...state[creatorProjectId],
                    ticket: action.payload
                };
            }
        });

        builder.addCase(
            refreshToken.fulfilled,
            (state, { payload }: PayloadAction<TokenPayload>) => {
                state[payload?.creatorProjectId] = {
                    ...state[payload?.creatorProjectId],
                    ticket: payload.ticket
                };
            }
        );

        builder.addCase(exchangeCode.rejected, (_, { error }) => {
            throw error;
        });

        builder.addCase(refreshToken.rejected, (_, { error }) => {
            throw error;
        });
    }
});

export const { setCreatorCustomerTicket, setPasswordClaims } =
    creatorCustomersSlice.actions;

export default creatorCustomersSlice.reducer as Reducer<CreatorCustomersState>;
