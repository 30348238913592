import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICreatorCustomerTicket, TokenPayload } from "./types";
import { RootState } from "store/reducers";
import { Guid } from "helpers/guids";

const apiBaseUrl =
    import.meta.env.VITE_API_URL ||
    "https://silversunnapi-develop.azurewebsites.net";

export const exchangeCode = createAsyncThunk(
    "creatorCustomers/exchangeCode",
    async (code: string) => {
        const formData = new URLSearchParams();
        formData.append("grant_type", "authorization_code");
        formData.append("client_id", "SwitcherPlayer");
        formData.append("code", code);

        const res = await fetch(`${apiBaseUrl}/token`, {
            method: "POST",
            body: formData
        });

        const text = await res.text();
        if (!res.ok) {
            throw new Error(text);
        }

        const ticket = JSON.parse(text) as ICreatorCustomerTicket;
        return ticket;
    }
);

export const refreshToken = createAsyncThunk<
    TokenPayload,
    void,
    {
        state: RootState;
    }
>("creatorCustomers/refreshToken", async (_, { getState }) => {
    const tokenUrl = apiBaseUrl + "/Token";
    const state = getState();
    const formData = new URLSearchParams();
    const creatorProjectId =
        state?.playerCreator?.details?.projectId ?? Guid.empty;
    const oldRefreshToken =
        state?.creatorCustomers[creatorProjectId ?? ""]?.ticket?.refresh_token;

    if (!oldRefreshToken) throw new Error();

    formData.append("grant_type", "refresh_token");
    formData.append("refresh_token", oldRefreshToken);
    formData.append("client_id", "SwitcherPlayer");

    const tokenRes = await fetch(tokenUrl, {
        method: "POST",
        body: formData
    });

    const text = await tokenRes.text();
    if (!tokenRes.ok) {
        throw new Error(text);
    }

    const ticket = JSON.parse(text) as ICreatorCustomerTicket;
    return { ticket, creatorProjectId };
});
