import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../TextInput";

export interface EmailInputProps {
    value: string;
    error?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    isLoading: boolean;
    label?: string;
}

export const EmailInput: React.FC<EmailInputProps> = ({
    value,
    error,
    onChange,
    isLoading,
    label
}) => {
    const { t } = useTranslation();

    return (
        <>
            <TextInput
                id="email"
                type="email"
                value={value}
                error={error}
                onChange={onChange}
                label={label ?? t("inputs:email")}
                disabled={isLoading}
            />
        </>
    );
};
